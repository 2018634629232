import * as chartColors from '@hypefactors/shared/js/components/charts/DefaultChartColors'

export const SENTIMENT_TYPES = {
  very_positive: {
    label: 'pages.coverage.rating.very_positive',
    icon: 'smiley-very-positive-o',
    icon_full: 'smiley-very-positive-full',
    color: chartColors.blue
  },
  positive: {
    label: 'pages.coverage.rating.positive',
    icon: 'smiley-positive-o',
    icon_full: 'smiley-positive-full',
    color: chartColors.charcoal
  },
  neutral: {
    label: 'pages.coverage.rating.neutral',
    icon: 'smiley-neutral-o',
    icon_full: 'smiley-neutral-full',
    color: chartColors.brick
  },
  negative: {
    label: 'pages.coverage.rating.negative',
    icon: 'smiley-negative-o',
    icon_full: 'smiley-negative-full',
    color: chartColors.grey
  },
  very_negative: {
    label: 'pages.coverage.rating.very_negative',
    icon: 'smiley-very-negative-o',
    icon_full: 'smiley-very-negative-full',
    color: chartColors.salmon
  }
}

export const SENTIMENT_TYPES_ARRAY = Object.entries(SENTIMENT_TYPES).map(([key, sentiment]) => ({ key, ...sentiment }))

export const SENTIMENT_ORDER = ['very_positive', 'positive', 'neutral', 'negative', 'very_negative']

export const SENTIMENT_SLUG_TO_NUMBER = {
  'very_negative': 1,
  'negative': 2,
  'neutral': 3,
  'positive': 4,
  'very_positive': 5
}

export const SENTIMENT_NUMBER_TO_SLUG = {
  1: 'very_negative',
  2: 'negative',
  3: 'neutral',
  4: 'positive',
  5: 'very_positive'
}
